/* .no-display-flex .mat-grid-tile-content{
  display: unset !important;
} */

body .mat-option {
  text-align: left !important;
}

.my-mat-menu {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
}

/* .cdk-overlay-pane {
  position: absolute;
  left: 0px !important;
  width: 100% !important;
  top: 100px !important;
  right: unset;
} */

.cdk-overlay-pane .mat-menu-panel {
  width: 100% !important;
  max-width: 100% !important;
}

.mat-toolbar.transparent {
  background: transparent !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.top-nav .logo {
  width: 250px;
}

.top-nav {
  padding-top: 30px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.toolbar-flex {
  width: 100% !important;
  justify-content: flex-end !important;
}

.toolbar-flex button:last-child {
  margin-right: 0px !important;
}

@media (min-width: 1600px) {
  .top-nav {
    width: 70% !important;
    max-width: 70% !important;
  }
}

.router-container {
  /* width: 80%; */
  margin: auto;
  margin-top: -6% !important;
}


/* 17 March 2023, Gurb - Removed width from router-container & header, now width is on container only */

@media (min-width: 1500px) {
  .router-container {
    margin-top: -4% !important;
  }
}

/* @media (max-width: 1300px) {
  .router-container {
    width: 90% !important;
  }
}

@media (max-width: 1200px) {
  .router-container {
    width: 90% !important;
  }
}

@media (max-width: 1150px) {
  .router-container {
    width: 92% !important;
  }
}

@media (max-width: 1100px) {
  .router-container {
    width: 92% !important;
  }
} */

@media (max-width: 992px) {
  /* .router-container {
    width: 96% !important;
  } */
  .container {
    max-width: 910px !important;
  }
  .logo-div img {
    width: 200px !important;
  }
  .logo-div {
    max-width: 30% !important;
  }
  .menu-div-right {
    max-width: 70% !important;
  }
  .mat-toolbar.transparent {
    justify-content: space-between;
    padding: 0 17px !important;
  }
}

@media (max-width: 768px) {

  body .radio-left-right .col-6:nth-child(1) p label {
      width: 85% !important;
      padding-left: 0px !important;
  }

  .offset-sm-3 {
    margin-left: 0px !important;
  }

  .radio-left-right .row {
    margin-left: 0px !important;
  }

  .radio-left-right .col-6 {
    padding-left: 0px !important;
  }

  .radio-left-right.row {
    margin-left: 0px !important;
  }

  body .half-field {
    width: 100% !important;
  }

  .wegLogic .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .top-nav .logo {
    width: 200px;
  }
  body .example-radio-group {
    flex-direction: column !important;
  }
  /* For Responsiveness */
  .two-cols .mat-grid-tile-content {
    display: block !important;
  }

  body .two-cols .mat-form-field {
    width: 100% !important;
  }
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.top-head {
  background-color: #CBA857;
  background-size: cover;
  height: 300px;
  border-radius: 0 0 30px 30px;
  background-image: url('/assets/Vertragsexpert_Logo_RGB_bildmarke-1024x782 - Kopie.png');
}

body {
  background: #f8f2e5;
  height: 100vh;
  margin-top: 0 !important;
  position: relative;
}
/* body * {
  color: #272844;
} */
body > div {
  border: none !important;
}

h1,
h2,
h3,
h4,
h5 {
  color: #272844 !important;
}

h1 {
  font-size: 30px !important;
  font-weight: bold !important;
}
@media screen and (max-width: 700px) {
  h1 {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 700px) {
  h2 {
    font-size: 20px !important;
  }
}

h3 {
  font-size: 20px;
  font-weight: bold;
  text-transform:uppercase!important;
  color:#272844!important;
  text-align:center!important;
  margin-top:3%!important;
}
@media screen and (max-width: 700px) {
  h3 {
    font-size: 12px;
  }
}

h4 {
    font-weight:700!important;
    font-size:18px!important;
  }

.top-container {
  background: #cba857 !important;
  background-size: 117% !important;
  background-repeat: no-repeat !important;
  background-position: 92% 41% !important;
  height: 310px;
  width: 100%;
  position: absolute;
  z-index: 100;
  border-radius: 0 0 50px 50px;
  padding-top: 40px;
  top: 0;
  left: 0;
}
.top-container .top-navi {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  align-items: center;
}
@media screen and (max-width: 700px) {
  .top-container .top-navi {
    flex-direction: column;
  }
}
.top-container .top-navi a,
.top-container .top-navi ul {
  display: flex;
  list-style: none;
  text-decoration: none;
  align-items: center;
  font-weight:300!important;
  margin: 0;
  padding: 0;
    color:#fff!important;
}
.top-container .top-navi a.brand {
  font-size: 1.4rem;
  font-weight: bold;
}
@media screen and (min-width: 850px) {
  .top-container .top-navi a.brand {
    font-size: 2.2rem;
  }
}
.top-container .top-navi a.brand:hover {
  color: #272844;
}
.top-container .top-navi a.brand img {
  margin-right: 15px;
}
.top-container .top-navi a.brand span {
  font-weight: lighter;
  margin: 0;
  padding: 0;
  padding-left: -10px;
}
@media screen and (max-width: 700px) {
  .top-container .top-navi ul {
    margin-top: 20px;
  }
}
.top-container .top-navi ul li {
  margin-right: 20px;
}
@media screen and (min-width: 850px) {
  .top-container .top-navi ul li {
    margin-right: 50px;
  }
}

.top-container .top-navi ul li:last-child {
  margin-right: 0;
}

/* @media (min-width: 1200px){
  body header {
    max-width: 1140px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
} */

header {
  position: relative;
  background: white;
  z-index: 9000;
  border: none !important;
  min-height: auto!important;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(203, 168, 87, 0.5) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /* width: 86% !important;
  margin-left: 7% !important; */
  text-align: center;
  top: -140px;
}

header h1 {
  text-transform: uppercase;
  margin-bottom: 18px;
 margin-top:5%!important;
}
header label {
  max-width: 600px;
  font-size: 12px;
    font-style:italic;
}
header h3 {
  margin-top: 18px;
}

.custom-weiter {
  background-color: transparent !important;
}


.progress-wrapper {
  display: flex;
  justify-content: center;
  min-width: 100%;
  position: absolute;
  margin: 0;
  bottom: -40px;
  left: 0;
}
.progress-wrapper .progress-open,
.progress-wrapper .progress-active,
.progress-wrapper .progress-finished {
  min-width: 60px;
  align-items: center;
  box-shadow: 0 4px 6px rgba(203, 168, 87, 0.5);
  background: #fff;
  display: flex;
  margin: 10px;
  height: 60px;
  border-radius: 50%;
  padding: 0 !important;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: bold;
}
.progress-wrapper .progress-active {
  background: #cba857;
}
.progress-wrapper .progress-finished {
  background: #272844;
}

.cdk-overlay-pane {
  width: 500px;
}

.mat-form-field {
  width: 100%;
  margin-top: 10px;
  border: 0;
  background: transparent;
display:block!important;
}
.mat-form-field .mat-form-field-infix {
  position: relative;
}
.mat-form-field .mat-form-field-infix .mat-form-field-label-wrapper {
  position: absolute;
  /* top: -3px; */
  left: 17px;
  font-size:0.9rem!important;
}

.custom-box {
  background-color: transparent;
  margin-top: 1%;
  margin-bottom: 1%;
  width: 95%;
  border: 1px solid rgba(203, 168, 87, 0.5);
  padding: 10px;
}

.mat-raised-button {
  background: #272844 !important;
  /* background-image: url("./assets/v_logo.png") !important; */
  background-size: 80% !important;
  font-size: 16px;
  height: 50px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px 20px;
  margin: 5px;
  border-radius: 25px !important;
  min-width: 350px;
  align-items: center;
  justify-content: center;
}
.mat-raised-button:focus {
  outline: none;
}
.mat-raised-button:hover {
  background-color: rgba(203, 168, 87, 0.5) !important;
}

.custom-weiter-div {
  display: flex;
  align-items: center;
  justify-content: center!important;
  border-top:2px solid #cba857 !important;
  padding-top:3%!important;
}

input:not([type=radio], [type=checkbox], [type=file]),
select,
.select, .mat-select {
  position: relative;
  width: 100%;
  height: 46px !important;
  border: 1px solid transparent;
  border-radius: 15px;
  outline: none;
  padding: 0 20px;
  font-size: 1.2rem;
  margin: 5px;
  background: #fff;
  transition: all 0.3s;
}
input:not([type=radio], [type=checkbox], [type=file]):focus, input:not([type=radio], [type=checkbox], [type=file]).active,
select:focus,
select.active,
.select:focus,
.select.active, .mat-select:focus, .mat-select.active {
  border: 1px solid rgba(203, 168, 87, 0.5);
  box-shadow: 0 0 6px rgba(203, 168, 87, 0.5);
}

.mat-select {
  padding: 11px 20px;
}

textarea {
  border: 1px solid rgba(203, 168, 87, 0.5);
}

.custom-radio {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-radio label {
  cursor: pointer;
  padding: 0 0 0 25px;
  z-index: 10;
}

.custom-radio:before {
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  border: 1px solid #272844;
  border-radius: 50%;
  left: 0;
}
.custom-radio input[type=radio] {
  display: none;
}
.custom-radio input[type=radio]:checked ~ label:before {
  width: 16px;
  height: 16px;
  content: "";
  position: absolute;
  background: #272844;
  border-radius: 50%;
  left: 2px;
  top: 1px;
}

.custom-checkbox {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.custom-checkbox label {
  cursor: pointer;
  padding: 0 0 0 25px;
  z-index: 10;
}
.custom-checkbox:before {
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  border: 1px solid #272844;
  left: 0;
}
.custom-checkbox input[type=checkbox] {
  display: none;
}
.custom-checkbox input[type=checkbox]:checked ~ label:before {
  width: 16px;
  height: 16px;
  content: "";
  position: absolute;
  background: #272844;
  left: 2px;
  top: 1px;
}

.select {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.select:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 10px;
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  background: #272844;
  right: 20px;
}
.select select {
  display: none;
}
.select.active ul {
  visibility: visible;
}
.select ul {
  position: absolute;
  visibility: hidden;
  z-index: 999;
  margin: 0;
  padding: 0;
  top: 50px;
  left: 0;
  list-style: none;
  background: #fff;
  width: 100%;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0 0 6px rgba(203, 168, 87, 0.5);
}
.select ul li {
  border-bottom: 1px solid rgba(203, 168, 87, 0.5);
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
}
.select ul li:hover {
  background: rgba(203, 168, 87, 0.1);
}
.select ul li:last-child {
  border-bottom: 0;
  padding: 10px 0;
}

.fa-question, .fa-info {
    background-color:#CBA858 !important;
    color:#272844 !important;
    border-radius:50%!important;
    font-weight:bold!important;
    padding-left:10px!important;
    padding-right:10px!important;
    width: 23px !important;
    height: 23px !important;
  }

.fa-info {
  width: 22px!important;
  height: 22px!important;
}
/* .container{margin-top:10%!important;} */


/*# sourceMappingURL=custom.css.map */
.example-radio-group[_ngcontent-ihx-c1] {
  display: flex;
  /* flex-direction: column; */
  margin: 15px 0;
  /* align-content: stretch; */
  justify-content: center !important;
}
.example-radio-group{
  flex-direction: row !important;
  justify-content: center !important;
  /* margin: 0px 190px !important; */
}



#example-radio-group-label{
  margin: 0px 20px !important;
  text-align: center !important;
}
.custom-top-line{
  grid-column-gap:  0px !important;
  grid-template-columns: auto auto  !important;
  padding: 10px;
}
.ng-star-inserted{
  text-align: center !important;
}
span.mat-button-wrapper {
  color: white !important;
    font-size:0.6rem!important;
}

.example-form{
 /*  display: grid !important;*/
  grid-column-gap: 50px !important;
  grid-template-columns: auto auto  !important;
}
/* .custom-box{
 splay: grid !important;
  grid-column-gap: 50px !important; di
  /* grid-template-columns: auto auto  !important;
  padding: 10px; */ */
/* .ng-star-inserted {
  display: grid !important;
  grid-template-columns: auto auto !important;
  align-items: baseline !important;
  align-content: center !important;
} */
/* path {
  color: white !important;
} */
.fa-question path {
  fill: white !important;
}

.fa-info{
  border-radius: 1 !important;
  background-color: #CBA857 !important;
  padding-left:10px!important;
  padding-right:10px!important;
  float:none!important;
}

.fa-info path{
  color: #272844 !important;
}

a.brand {
  color: black !important;
  font-weight: bold !important;
}
span{
  font-weight: bold;
}
a {
  color: black;
  font-weight: bold;
  text-decoration: none;
  /* background-color: transparent; */
}
svg.mat-datepicker-toggle-default-icon.ng-star-inserted {
  fill: black !important;
}
/*
.um-col-1{
  float: left !important;
  width: 50%  !important;
  padding: 10px  !important;
  height: 300px  !important;
} */
/* //reg form */
/* div.um-col-1 {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  grid-gap: 20px !important;
} */


.um-col-1{
      display: grid;
      grid-template-columns: 1fr 1fr;
}
.mat-form-field .mat-form-field-infix {
  position: relative;
  display: inline-grid !important;
  font-size:0.9rem!important;
  /* margin-top:-3%!important; */
}

.custom-icon-div {
  margin: 45px 9px 16px 11px;
  background: none;
  display: none !important;
}
/* .custom-box.ng-star-inserted {
  margin: 1px 2px  !important;
  margin-bottom: 32px;
} */

mat-form-field.no-padding.no-line.mat-form-field.ng-tns-c2-24.mat-primary.mat-form-field-type-mat-input.mat-form-field-can-float.mat-form-field-has-label.ng-pristine.ng-invalid.mat-form-field-invalid.mat-form-field-hide-placeholder.ng-touched {
  margin: 1px 1px 10px -17px !important;

}
mat-form-field.no-padding.no-line.mat-form-field.ng-tns-c2-25.mat-primary.mat-form-field-type-mat-select.mat-form-field-can-float.mat-form-field-has-label.mat-form-field-hide-placeholder.ng-untouched.ng-pristine.ng-invalid {

  margin: 1px 24px 0px -55px !important;
}
/* form.ng-untouched.ng-pristine.ng-invalid {
  margin: 1px 92px 0px -20px  !important;
}*/
/*div {
  text-align: initial !important;
}*/


.label[_ngcontent-ube-c1]{display:none!important;}

.custom-top-line{border-top:1px solid #cba857 !important; }

.fa-times{background-color:transparent!important;}

.cdk-text-field-autofill-monitored:-webkit-autofill{font-size:0.9rem!important;}
.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {font-size:0.9rem!important;}

.table{width:100%!important;text-align:left!important;border:1px solid #cba857 !important;border-collapse:inherit;}

.mat-checkbox-layout .mat-checkbox-label{font-weight:400!important;}
.mat-checkbox-layout .mat-checkbox-label.a{font-weight:400!important;color:red!important;}

.fa-w-14{display:none!important;}

.cdk-textarea-autosize mat-i8uunput-element mat-form-field-autofill-control cdk-text-field-autofill-monitored ng-untouched ng-pristine ng-invalid{border:1px solid #cba857 !important}

.mat-form-field.error input, .mat-form-field.error textarea {
  border: 1px #dc3545 solid;
}

.mat-form-field.validation-error.ng-invalid input, .mat-form-field.validation-error.ng-invalid mat-select {
  border: 1px #dc3545 solid;
}

.mat-form-field.validation-error.ng-invalid.datePickerField input, .mat-form-field.validation-error.ng-invalid.datePickerField mat-select {
  border: 1px #dc3545 solid;
  border-right: none !important;
}

.mat-form-field.validation-error.ng-invalid.datePickerField button, .mat-form-field.validation-error.ng-invalid.datePickerField mat-select {
  border: 1px #dc3545 solid;
  border-left: none !important;
}

.mat-grid-tile-content-no-flex .mat-grid-tile-content {
  display: block !important;
}

/* removed panku */
/* .full-grid-tile {
  padding: 0 10px !important;
} */

.full-grid-tile mat-grid-tile {
  width: 100% !important;
}

.load-json-page textarea {
  background: #fff;
  border: 1px solid rgba(203, 168, 87, 0.5);
  border-radius: 15px;
  padding: 15px !important;
  font-size: 1.2rem;
  margin: 5px;
  transition: all 0.3s;
  height: 100px;
}

.mat-select {
  margin-left: 0px !important;
}

/* New */

.custom-box {
  width: 100% !important
}

.mat-form-field {
  margin: 0px !important;
  width: 100% !important;
}

.two-cols .mat-grid-tile-content {
  justify-content: space-between !important;
}

.two-cols .mat-form-field {
  width: 49.50% !important;
}

.wid100 {
  width: 100% !important;
}

body .text-right {
  text-align: right !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.custom-box .mat-form-field {
    margin-left: 0 !important;
}

input:not([type=radio], [type=checkbox], [type=file]),
select,
.select, .mat-select {
  margin: 0px !important;
}


/* .mat-form-field .mat-form-field-infix .mat-form-field-label-wrapper {
  top: -14px !important;
} */

.mat-select-panel mat-option .mat-option-text, .mat-select-value-text span {
  font-size: 0.9rem !important;
  font-weight: 400 !important;
}

mat-form-field:not(.mat-form-field-should-float) .mat-form-field-label {
  top: 30px !important;
}

.mat-radio-checked .mat-radio-inner-circle {
    transform: scale(0.79) !important;
}

.mat-radio-inner-circle {
    left: 1px !important;
    top: 1px !important;
}

.mat-radio-outer-circle {
  height: 22px !important;
  width: 22px !important;
}

.mat-radio-button .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #272844 !important;
}

.mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
    border-color: #272844 !important;
}

.bg-button {
  background: #CBA857 url('/assets/v_logo.png') 0% 0% !important;
  background-color: #CBA857 !important;
  background-size: 105% 198% !important;
  background-repeat: no-repeat !important;
  padding: 0px 25px !important;
}

.bg-button .mat-button-wrapper {
  color: #272844 !important;
}

.bg-button:hover {
  background-color: #CBA857 !important;
}

.mat-select-value-text span {
  font-size: 13px !important;
  font-style: italic;
}

.fa-info-label {
  position: relative;
  top: 3px;
  background-color: #272844 !important;
  height: 25px;
  width: 25px;
  border-radius: 100px;
}

.fa-info:hover {
  background-color: #272844 !important;
}

.fa-question:hover {
  background-color: #CBA858 !important;
  color: #fff !important;
}

input[type=checkbox] {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

input[type=checkbox]:checked {
    background-color: gray;
}

.mat-checkbox-background, .mat-checkbox-frame {
    border-radius: 70% !important;
}

.mat-checkbox-inner-container {
  margin-top: 1px !important;
  height: 20px !important;
  width: 20px !important;
}

.checkbox-div {
  width: 370px;
  margin: auto;
}

.custom-weiter-div {
  width: 100% !important;
}

/* .mat-checkbox-checkmark-path {
  stroke: #272844 !important;
} */

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #272844 !important;
}

table.table-td-left tr td:first-child {
  text-align: left !important;
}

.start-grid .mat-grid-tile-content {
  padding: 15px !important;
}

@media (max-width: 500px) {
  .matFigurepad15 .mat-grid-tile-content {
    padding: 15px !important;
  }
}

.mat-form-field-infix {
    border-top: 0.60em solid transparent !important;
}

body .datePickerField .mat-form-field-suffix button {
  background-color: #fff !important;
  border-radius: 0 15px 15px 0 !important;
  position: relative;
  top: 3px;
  height: 46px !important;
}

.datePickerField input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button, .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
    height: 46px !important;
}

@media (min-width: 768px) {
  /* .mat-form-field-div {
    width: 49.50% !important;
  } */
  .two-cols .mat-form-field-div .mat-form-field {
    width: 100% !important;
  }
}

.mat-form-field {
  text-align: center !important;
}

.border-solid {

}

.yellow-line {
  border-top: 2px solid #cba857 !important;
}

.yellow-line-2 {
  border-top: 1px solid #cba857 !important;
}

mat-dialog-container {
  padding: 0 !important;
}

mat-dialog-content {
  margin: 0px !important;
  padding: 0px !important;
}

.mat-dialog-container {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.mat-dialog-content {
  overflow: hidden !important;
}

.custom-dlg-body {
  overflow-y: auto !important;
  max-height: 50vh !important;
  background-color: #fff;
  padding: 0 34px !important;
  text-align: left;
}

.custom-dlg-head {
  background-color: #CBA857;
  padding: 15px;
  border-radius: 30px 30px 0px 0px;
}

.custom-dlg-footer {
  height: 115px;
  background-color: #fff;
  padding: 0 15px;
  padding-top: 15px;
  border-radius: 0px 0px 30px 30px !important;
}

.custom-dlg-footer .mat-raised-button {
  margin-bottom: 0px !important;
  margin-right: 0px !important;
}

mat-dialog-content {
  max-height: 85vh !important;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-dlg-body .custom-dlg-h {
  border-bottom: none;
}

.text-leftt {
  text-align: left !important;
}

.mat-raised-button.small-btn {
  height: 28px !important;
  padding: 0px 20px !important;
  line-height: 1 !important;
}

.mat-raised-button.yellow-bg {
    background: #272844 !important;
    background-image: url('/assets/v_logo.png') !important;
}

.mat-raised-button {
  height: 38px !important;
}

.radio-left-right {
  border: none !important;
}

.radio-left-right .col-6:nth-child(2) {
  text-align: left !important;
  justify-content: flex-start !important;
  padding-left: 0px !important;
}

.radio-left-right .col-6:nth-child(1) p label {
  width: 70% !important;
  text-align: left !important;
  color: #272844 !important;
  margin-top: 0px !important;
  vertical-align: top;
  margin-right: 20px !important;
  padding-left: 15px !important;
}

.radio-left-right .col-6:nth-child(1) p span + fa-icon {
  position: relative !important;
  top: 5px !important;
}

.radio-left-right p {
  font-weight: bold !important;
  margin-bottom: 0px !important;
  margin-top: 7px !important;
  text-align: left !important;
}

.radio-left-right mat-radio-group {
  text-align: left;
  width: 100%;
}

.half-field {
  width: 49.50% !important;
}

.custom-kosten {
  background-color: #fff !important;
  color: black !important;
  box-shadow: 0px 3px 6px #c49e54;
  border-radius: 30px;
  width: 90%;
  margin: auto;
}

.popup .custom-kosten {
  width: 70% !important;
}

.custom-kosten h3 {
  background-color: #c49e54 !important;
  border-radius: 30px 30px 0 0;
  padding: 28px;
}

.popup .custom-kosten h3 {
  padding: 15px;
  font-size: 15px;
}

.custom-kosten .inner-table {
  padding: 30px 0;
}

.popup .custom-kosten .inner-table {
  padding: 15px 0;
}

.custom-kosten .inner-table td {
  padding: 5px !important;
  font-size: 14px !important;
}

.textarea {
  background-color: #fff !important;
  border-radius: 15px;
  border: none !important;
}

.custom-box {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mat-form-field-label, .mat-input-element {
  text-align: left !important;
}

.mat-form-field-label mat-label {
  font-size: 14px !important;
}

.mat-radio-button {
  min-width: 130px !important;
  text-align: left !important;
}

/* .mat-grid-list {
  height: 100% !important;
} */

.wegLogic .fa-question {
  float: none !important;
}

.text-bold {
  font-weight: bold !important;
}

.text-yellow {
  color: #cba857 !important;
}

.text-yellow {
  color: #cba857 !important;
}

.bitte-textarea {
  background-color: #fff !important;
  border-radius: 14px;
  padding: 16px !important;
  color: #272844
}

.textarea {
  background-color: #fff !important;
  border-radius: 14px;
  padding: 16px !important;
  color: #272844
}

.mat-form-field-label mat-label {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-style: italic !important;
  color: #272844 !important;
}

.small-height-button {
  height: 27px !important;
  padding: 0 15px !important;
  line-height: 0px !important;
}

.mat-checkbox-label a {
  color: #272844!important;
}

body {
  color: #272844!important;
}

body .custom-dlg-head h4 {
  color: #272844 !important;
}

.mat-select-value {
  text-align: left;
}

.yellow-bgg {
  background-color: #CBA857 !important;
}

img.radio-image {
  width: 20px;
}

.liegenschaft-box + .liegenschaft-box {
  border-top: 3px solid rgba(203, 168, 87, 0.5) !important;
}

mat-radio-group.variant-error .mat-radio-outer-circle , mat-radio-group.ng-invalid.validation-error .mat-radio-outer-circle, mat-checkbox.ng-invalid.validation-error .mat-checkbox-frame {
  border-color: #dc3545 !important;
  box-shadow: 0px 0px 4px 1px #dc3545;
}

textarea.ng-invalid.validation-error {
  border: 1px solid #dc3545 !important;
}

label.error-message {
  display: none;
}

mat-form-field.error label.error-message , mat-form-field.validation-error.ng-invalid label.error-message {
  display: block;
}

.mat-input-element {
  box-sizing: border-box !important;
}

.mat-option {
    font-size: 16px !important;
}

.mat-snack-bar-container {
  background: #ffffff;
}

.mat-snack-bar-container .mat-simple-snack-bar-content {
  font-weight: 400;
  color: #424242
}

mat-paginator span.mat-button-wrapper {
  color: unset !important;
}

.dark-blue-bg {
  background-color: #272844 !important;
  color: #fff !important;
}

td.mat-cell div {
  text-align:left !important;
}

th.mat-header-cell {
  text-align: left !important;
}

body .cdk-overlay-pane .mat-menu-panel.wid-100-menu {
  width: 100px !important;
}
